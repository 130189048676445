import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/molecules/Footer";
import Topbar from "./components/molecules/Topbar";
import BookPage from "./pages/BookPage/BookPage";
import RouteEndpoint from "./utils/RouteEndpoints";

function App() {
  return (
    <BrowserRouter>
      <Topbar />
      <main>
        <Routes>
          <Route path={RouteEndpoint.RFJS_BOOK} element={<BookPage />} />
          <Route
            path="*"
            element={<Navigate to={RouteEndpoint.RFJS_BOOK} replace />}
          />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
