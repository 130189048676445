import React from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/logo.svg";
import RouteEndpoint from "../../utils/RouteEndpoints";

const Logo = (props) => {
  return (
    <Link
      to={RouteEndpoint.RFJS_BOOK}
      className="inline-block"
      onClick={props.onClick}
    >
      <img src={LogoIcon} alt="Qaifi Khan Logo" className="h-12" />
    </Link>
  );
};

export default Logo;
