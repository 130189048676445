import React from "react";

const Button = (props) => {
  const classes =
    "bg-accent text-white font-magnify font-light inline-block py-3 px-6 rounded-lg text-lg tracking-wide leading-[18px] shadow-white".split(
      " "
    );

  if (props.fullWidth) classes.push("w-full");

  return (
    <button onClick={props.click} className={classes.join(" ")}>
      {props.label}
    </button>
  );
};

export default Button;
