import React from "react";
import TestimonialCard from "./TestimonialCard";

const TestimonialSection = () => {
  const data = [
    {
      id: 1,
      rating: 5,
      message:
        "The book is nicely structured and the code snippets are helpful for hands on practice. It also covers the breadth of topics.",
      author: "Chinmay Singh",
      occupation: "",
    },
    {
      id: 2,
      rating: 5,
      message:
        "Well structured and easy to understand content. The questions in each chapter help to test how much I actually understood.",
      author: "Ankur Singh",
      occupation: "Lead Fronted Developer",
    },
    {
      id: 3,
      rating: 5,
      message:
        "I knew fundamentals of HTML, CSS and JavaScript. This book helped me learn ReactJS and got me into a frontend job.",
      author: "Osama Shaikh",
      occupation: "Lead Fronted Developer",
    },
    // {
    //   id: 4,
    //   rating: 5,
    //   message:
    //     "Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor.",
    //   author: "Qaifi Khan",
    //   occupation: "Lead Fronted Developer",
    // },
    // {
    //   id: 5,
    //   rating: 5,
    //   message:
    //     "Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor.",
    //   author: "Qaifi Khan",
    //   occupation: "Lead Fronted Developer",
    // },
    // {
    //   id: 6,
    //   rating: 5,
    //   message:
    //     "Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor. Lorem Ipsum Dolor.",
    //   author: "Qaifi Khan",
    //   occupation: "Lead Fronted Developer",
    // },
  ];

  const cards = data.map((item) => {
    return <TestimonialCard key={item.id} {...item} />;
  });

  return (
    <div className="w-full bg-[url('/src/assets/full-section-bg.svg')] bg-no-repeat bg-right bg-cover md:text-[4rem]">
      <div className="py-[20%] pb-[40%] md:py-[4%] container mx-auto">
        <h1 className="text-section-heading text-white text-center mb-[4%]">
          Words from Early-Access Learners
        </h1>

        <div className="grid px-4 md:px-0 md:grid-cols-3 gap-4">{cards}</div>
      </div>
    </div>
  );
};

export default TestimonialSection;
