import { IMAGE_KEY } from "./Constants";

import BookCoverLarge from "../assets/book-cover-lg.png";
import RatingBar1 from "../assets/star-1.png";
import RatingBar2 from "../assets/star-2.png";
import RatingBar3 from "../assets/star-3.png";
import RatingBar4 from "../assets/star-4.png";
import RatingBar5 from "../assets/star-5.png";

const loadImage = (type) => {
  switch (type) {
    case IMAGE_KEY.BOOK_COVER_LARGE:
      return BookCoverLarge;
    case IMAGE_KEY.RATING_BAR_1:
      return RatingBar1;
    case IMAGE_KEY.RATING_BAR_2:
      return RatingBar2;
    case IMAGE_KEY.RATING_BAR_3:
      return RatingBar3;
    case IMAGE_KEY.RATING_BAR_4:
      return RatingBar4;
    case IMAGE_KEY.RATING_BAR_5:
      return RatingBar5;
    default:
      return null;
  }
};

export default loadImage;
