import React from "react";
import RouteEndpoint from "../../utils/RouteEndpoints";
import Logo from "../atoms/Logo";
import MenuItem from "../atoms/MenuItem";
import Sidebar from "./Sidebar";

const Topbar = () => {
  return (
    <div className="w-full flex items-center justify-between md:justify-start bg-transparent max-w-[100%] md:max-w-[50%] absolute px-4 md:px-[8%] py-[4%] md:py-[2%]">
      <Logo />
      <Sidebar />
      <div className="ml-14 hidden md:block">
        <MenuItem to={RouteEndpoint.RFJS_BOOK} label="Books" />
        <MenuItem to={RouteEndpoint.COURSE_LIST} external label="Courses" />
        <MenuItem to={RouteEndpoint.BLOGS} external label="Blogs" />
      </div>
    </div>
  );
};

export default Topbar;
