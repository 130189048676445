import React from "react";
import Helmet from "react-helmet";
import AboutSection from "./components/AboutSection";
import LandingSection from "./components/LandingSection";
import OrderSection from "./components/OrderSection";
import TestimonialSection from "./components/TestimonialSection";

const BookPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ReactJS for Job Seekers by Qaifi Khan</title>
        <link
          rel="canonical"
          href="https://qaifikhan.com/react-for-job-seekers"
        />
        <meta
          name="description"
          content="This book will help you learn everything you need to know about ReactJS to start working as a front-end developer. The book begins by talking about the core concepts like components, state, props, lifecycle, and hooks, which will get you comfortable with the ReactJS ecosystem. The book also talks about additional topics like routing, connecting to the backend, and handling state using Redux to give you a more holistic understanding of building production-level applications using ReactJS."
        />
      </Helmet>
      <LandingSection />
      <AboutSection />
      <TestimonialSection />
      <OrderSection />
    </div>
  );
};

export default BookPage;
