import React from "react";
import RouteEndpoint from "../../utils/RouteEndpoints";
import Logo from "../atoms/Logo";
import MenuItem from "../atoms/MenuItem";

const Sidebar = () => {
  const [open, setOpen] = React.useState(false);

  const sidebarClasses =
    `bg-[#ffffff] w-[270px] h-[100vh] fixed top-0 right-0 shadow-gray translate-x-[270px] transition-all duration-150 ease-out`.split(
      " "
    );

  if (open) {
    sidebarClasses.push("translate-x-0");
  }

  return (
    <div className="block md:hidden">
      {!open ? null : (
        <>
          <div className="w-[100px] flex bg:white justify-start fixed left-0 top-0 w-full z-[100]">
            <div
              className="bg-[#ffffff] md:bg-white rounded-lg shadow-gray px-3 py-1 m-4 text-center md:m-8 cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <i className="fa-solid fa-xmark text-4xl text-dark-gray"></i>
            </div>
          </div>
          <div className="w-full h-[100vh] bg-black opacity-95 fixed top-0 left-0 right-0 bottom-0"></div>
        </>
      )}
      <div onClick={() => setOpen(true)}>
        <i className="fa-solid fa-bars text-[32px]"></i>
      </div>
      <div className={sidebarClasses.join(" ")}>
        <div className="flex flex-col space-y-4 pt-10">
          <div className="text-center">
            <Logo onClick={() => setOpen(false)} />
          </div>
          <MenuItem
            to={RouteEndpoint.RFJS_BOOK}
            label="Books"
            onClick={() => setOpen(false)}
          />
          <MenuItem
            to={RouteEndpoint.COURSE_LIST}
            external
            label="Courses"
            onClick={() => setOpen(false)}
          />
          <MenuItem
            to={RouteEndpoint.BLOGS}
            external
            label="Blogs"
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
