import { Link } from "react-router-dom";

const MenuItem = (props) => {
  return props.external ? (
    <a
      href={props.to}
      className="inline-block bg-white md:hover:bg-accent mx-2 rounded-sm"
      target="_blank"
      rel="noreferrer"
      onClick={props.onClick}
    >
      <span className="inline-block uppercase text-sm font-medium tracking-wider text-dark-gray text-md px-2 py-1 mb-1 bg-white">
        {props.label}
      </span>
    </a>
  ) : (
    <Link
      to={props.to}
      className="inline-block bg-white md:hover:bg-accent mx-2 rounded-sm"
      onClick={props.onClick}
    >
      <span className="inline-block uppercase text-sm font-medium tracking-wider text-dark-gray text-md px-2 py-1 mb-1 bg-white">
        {props.label}
      </span>
    </Link>
  );
};

export default MenuItem;
