export const STATES = {
  "Andhra Pradesh": [],
  "Arunachal Pradesh": [],
  Assam: [],
  Bihar: [],
  Chhattisgarh: [],
  Delhi: [],
  Goa: [],
  Gujarat: [],
  Haryana: [],
  "Himachal Pradesh": [],
  Jharkhand: [],
  Karnataka: [],
  Kerala: [],
  "Madhya Pradesh": [],
  Maharashtra: [],
  Manipur: [],
  Meghalaya: [],
  Mizoram: [],
  Nagaland: [],
  Odisha: [],
  Punjab: [],
  Rajasthan: [],
  Sikkim: [],
  "Tamil Nadu": [],
  Telangana: [],
  Tripura: [],
  "Uttar Pradesh": [],
  Uttarakhand: [],
  "West Bengal": [],
};

Object.freeze(STATES);
