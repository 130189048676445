import React, { useEffect, useRef } from "react";

import { useLocation } from "react-router";
import Button from "../../../components/atoms/Button";
import Dropdown from "../../../components/atoms/Dropdown";
import InputField from "../../../components/atoms/InputField";
import PhoneNumberField from "../../../components/molecules/PhoneNumberField";
import { IMAGE_KEY } from "../../../utils/Constants";
import loadImage from "../../../utils/ImageLoader";
import { STATES } from "../../../utils/StateAndCity";

const OrderSection = () => {
  const location = useLocation();
  const refMainDiv = useRef(null);

  const countryList = [
    {
      id: 1,
      label: "India",
      key: "IN",
    },
  ];
  const stateList = [...Object.keys(STATES)].map((item, pos) => ({
    id: pos,
    label: item,
    key: item.split(" ").join("_"),
  }));
  // console.log(stateList);

  useEffect(() => {
    if (
      location.hash === "#order" &&
      refMainDiv !== null &&
      refMainDiv !== undefined &&
      refMainDiv.current !== null &&
      refMainDiv.current !== undefined
    ) {
      refMainDiv.current.scrollIntoView();
    }
  }, [location.hash]);

  const onSubmitClick = (e) => {
    e.preventDefault();
    // https://docs.google.com/forms/d/e/1FAIpQLSfgStEm7_cA5mDOWs1rd9KrMEF8NG7Zy_gVy-gk9MOoawoorA/viewform?usp=pp_url&entry.690028860=Qaifi&entry.1527220420=9871534339&entry.1171491252=1&entry.919634976=Sarojini+Residency&entry.2027427437=Begur+AECS+Layout&entry.1343279374=+Karnataka&entry.1203673834=India&entry.1081446101=560068

    const data = {
      fullName: e.target.fullname.value,
      email: e.target.email.value,
      address1: e.target.address1.value,
      address2: e.target.address2.value,
      country: e.target.country.value,
      state: e.target.state.value,
      city: e.target.city.value,
      postalCode: e.target.pincode.value,
      countryCode: e.target.countryCode.value,
      phone: e.target.phone.value,
    };

    // console.log(data);

    const newURL = `https://docs.google.com/forms/d/e/1FAIpQLSfgStEm7_cA5mDOWs1rd9KrMEF8NG7Zy_gVy-gk9MOoawoorA/viewform?usp=pp_url&entry.690028860=${
      data.fullName
    }&entry.1527220420=${
      data.countryCode + data.phone
    }&entry.1171491252=1&entry.919634976=${data.address1}&entry.2027427437=${
      data.address2
    }&entry.1203673834=${data.country}&entry.1343279374=${
      data.state
    }&entry.1111730479=${data.city}&entry.1081446101=${data.postalCode}`;

    window.open(encodeURI(newURL), "_blank");
  };

  return (
    <div
      ref={refMainDiv}
      className="text-center py-[20%] pt-0 mt-[-10%] md:mt-0 md:py-[4%] w-full min-h-screen bg-[url('/src/assets/light-bg.svg')] bg-no-repeat bg-top bg-cover"
    >
      <div className="container mx-auto w-full min-h-screen">
        <h1 className="bg-accent bg-clip-text text-transparent text-section-heading md:text-[4rem]">
          Get Your Signed Copy
        </h1>

        <div className="flex flex-col md:flex-row justify-between mt-8">
          <div className="w-[100%] md:w-[50%] flex flex-col md:flex-row items-center justify-center">
            <img
              className="max-w-[80%]"
              src={loadImage(IMAGE_KEY.BOOK_COVER_LARGE)}
              alt="React for Job Seekers Book Cover"
            />
          </div>
          <div className="w-[100%] md:px-0 md:w-[50%] flex justify-end">
            <iframe
              title="Order ReactJS for Job Seekers"
              frameborder="0"
              style={{ height: "1150px", width: "99%", border: "none" }}
              src="https://forms.zohopublic.in/qaifikhan/form/ReactJSBookOrder/formperma/_riAjd-kFTlK4K9sMcxmRhWegD05s_DZqTEZp5t7aFE"
              className="shadow-gray w-full max-w-[500px] py-[4%] px-[2%] text-left rounded-lg bg-white"
            ></iframe>
            {/* <form
              onSubmit={onSubmitClick}
              className="shadow-gray w-full max-w-[500px] p-[4%] text-left rounded-lg bg-white"
            >
              <h2 className="text-magnify text-2xl mb-5">
                Get Your Signed Copy Now
              </h2>
              <div className="space-y-3">
                <InputField
                  label="Full Name"
                  placeholder="Full Name"
                  name="fullname"
                />
                <InputField
                  label="Email"
                  placeholder="Email Address"
                  name="email"
                />
                <InputField
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  name="address1"
                />
                <InputField
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  name="address2"
                />
                <div className="grid grid-cols-2 gap-3">
                  <Dropdown
                    label="Country"
                    defaultVal={{ label: "India" }}
                    name="country"
                    dropdownOptions={countryList}
                    readonly
                  />
                  <Dropdown
                    label="State"
                    defaultVal={{ label: "Karnataka" }}
                    name="state"
                    dropdownOptions={stateList}
                  />
                </div>

                <div className="grid grid-cols-2 gap-3">
                  <InputField
                    label="City"
                    placeholder="Your City Name"
                    name="city"
                  />
                  <InputField
                    label="Postal Code"
                    placeholder="Postal Code"
                    name="pincode"
                  />
                </div>
                <PhoneNumberField label="Contact Number" />
              </div>
              <div className="w-full flex justify-center mt-[8%]">
                <Button label="Order Now @ INR 699" fullWidth />
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSection;
