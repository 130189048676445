export const IMAGE_KEY = {
  BOOK_COVER_LARGE: "BOOK_COVER_LARGE",
  RATING_BAR_1: "RATING_BAR_1",
  RATING_BAR_2: "RATING_BAR_2",
  RATING_BAR_3: "RATING_BAR_3",
  RATING_BAR_4: "RATING_BAR_4",
  RATING_BAR_5: "RATING_BAR_5",
};

Object.freeze(IMAGE_KEY);
