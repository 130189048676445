import React from "react";

const Footer = () => {
  return (
    <div className="bg-dark-gray text-off-white">
      <div className="container mx-auto py-[1.5%] px-4 flex flex-row md:flex-row items-center justify-between">
        <p className="text-sm flex items-center">
          <span>Made with</span>
          <i className="fa-solid fa-heart mx-2 text-red-600"></i>
          <span>in India</span>
        </p>

        <div className="space-x-6 text-lg">
          <a href="https://www.instagram.com/biologicalcoder/">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCBgV0KoA3bOrDHvjrihuLyw">
            <i className="fa-brands fa-youtube"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
