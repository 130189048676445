import React from "react";

import Preview1 from "../../assets/preview/preview-1.svg";
import Preview2 from "../../assets/preview/preview-2.svg";
import Preview3 from "../../assets/preview/preview-3.svg";
import Preview4 from "../../assets/preview/preview-4.svg";
import Preview5 from "../../assets/preview/preview-5.svg";
import Preview6 from "../../assets/preview/preview-6.svg";

const ViewSample = (props) => {
  const disableContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="fixed top-0 right-0 bottom-0 w-[100%] min-h-screen bg-white z-30 px-0 md:px-[8%] overflow-auto bg-[url('/src/assets/full-section-bg.svg')] bg-cover"
      onContextMenu={disableContextMenu}
    >
      <div className="flex bg:white justify-end fixed left-0 top-0 w-full z-[100]">
        <div
          className="bg-[#ffffff] md:bg-white rounded-lg shadow-gray px-3 py-1 m-4 text-center md:m-8 cursor-pointer"
          onClick={props.hideSampleView}
        >
          <i className="fa-solid fa-xmark text-4xl text-dark-gray"></i>
        </div>
      </div>
      <div className="container mx-auto max-w-[900px]">
        <img src={Preview1} alt="" />
        <img src={Preview2} alt="" />
        <img src={Preview3} alt="" />
        <img src={Preview4} alt="" />
        <img src={Preview5} alt="" />
        <img src={Preview6} alt="" />
      </div>
    </div>
  );
};

export default ViewSample;
