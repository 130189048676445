import React from "react";
import { IMAGE_KEY } from "../../../utils/Constants";
import loadImage from "../../../utils/ImageLoader";

const TestimonialCard = (props) => {
  const getRatingImg = (rating) => {
    switch (rating) {
      case 1:
        return loadImage(IMAGE_KEY.RATING_BAR_1);
      case 2:
        return loadImage(IMAGE_KEY.RATING_BAR_2);
      case 3:
        return loadImage(IMAGE_KEY.RATING_BAR_3);
      case 4:
        return loadImage(IMAGE_KEY.RATING_BAR_4);
      case 5:
        return loadImage(IMAGE_KEY.RATING_BAR_5);
      default:
        return null;
    }
  };

  return (
    <div className="bg-white rounded-lg p-4 w-full">
      <img
        className="max-h-6 ml-[-2px]"
        src={getRatingImg(props.rating)}
        alt="React for Job Seeker Rating"
      />
      <p className="text-dark-gray font-magnify font-light text-lg mt-2 leading-6">
        {props.message}
      </p>

      <p className="text-dark-gray font-magnify font-light text-lg mt-4">
        {props.author}
      </p>
      {/* <p className="text-dark-gray font-magnify font-thin text-sm opacity-70">
        {props.occupation}
      </p> */}
    </div>
  );
};

export default TestimonialCard;
