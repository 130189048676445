import React from "react";

const AboutSection = () => {
  return (
    <div className="container mx-auto text-center py-[20%] md:py-[4%]">
      <h1 className="bg-accent bg-clip-text text-transparent text-section-heading md:text-[4rem]">
        About the Book
      </h1>

      <div className="flex flex-col md:flex-row justify-between mt-8">
        <div className="w-full md:w-[60%] mx-auto px-4 md:px-0 text-center">
          <p className="text-dark-gray text-section-desc font-light">
            This book will help you learn everything you need to know about
            ReactJS to start working as a front-end developer. The book begins
            by talking about the core concepts like components, state, props,
            lifecycle, and hooks, which will get you comfortable with the
            ReactJS ecosystem.
          </p>
          <br />
          <p className="text-dark-gray text-section-desc font-light">
            The book also talks about additional topics like routing, connecting
            to the backend, and handling state using Redux to give you a more
            holistic understanding of building production-level applications
            using ReactJS.
          </p>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default AboutSection;
