import React from "react";
import { useNavigate } from "react-router";
import Button from "../../../components/atoms/Button";
import ViewSample from "../../../components/atoms/ViewSample";
import { IMAGE_KEY } from "../../../utils/Constants";
import loadImage from "../../../utils/ImageLoader";

const LandingSection = () => {
  const [showSample, setShowSample] = React.useState(false);

  const navigate = useNavigate();

  const onShowSampleClick = () => setShowSample(!showSample);

  const getStats = (heading, desc, isFirst = false, isLast = false) => {
    const statClasses = "pr-2 md:pr-4 pl-2 md:pl-0".split(" ");
    if (!isLast)
      statClasses.push("border-r border-dark-gray border-b-1 md:border-r-1");
    if (isFirst) statClasses.push("pl-0");

    return (
      <div className={statClasses.join(" ")}>
        <h3 className="text-dark-gray text-xl md:text-2xl">{heading}</h3>
        <p className="text-dark-gray font-light text-sm md:text-md">{desc}</p>
      </div>
    );
  };
  return (
    <section className="min-h-screen md:bg-[url('/src/assets/hero-bg-desktop.svg')] bg-no-repeat bg-right bg-contain">
      {showSample && <ViewSample hideSampleView={onShowSampleClick} />}

      <div className="container mx-auto flex flex-col md:flex-row justify-start items-center md:justify-between min-h-screen pt-[120px] md:pt-0 px-4 md:px-0">
        <div>
          <div>
            <h1 className="bg-accent bg-clip-text text-transparent text-section-heading md:text-[4rem]">
              ReactJS for Job Seekers
            </h1>
            <p className="text-dark-gray text-section-desc font-light text-[16px] md:text-[1.25rem]">
              The only guide you need to learn ReactJS and Crack Interviews
            </p>
          </div>
          <div className="hidden md:block mt-[26%]">
            <p className="text-dark-gray text-section-desc font-light">
              Authored By
            </p>
            <h2 className="bg-accent bg-clip-text text-transparent text-section-heading text-[2.5rem] ml-[-4px] mb-4">
              Qaifi Khan
            </h2>

            <div>
              <div className="flex space-x-4">
                {getStats("1.7 million", "Enrollments")}
                {getStats("4.5 rated", "Online Courses")}
                {getStats("3.7 million", "Minutes Taught", false, true)}
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-full md:max-w-[32%] flex flex-col items-end mt-[8%] md:mt-0">
          <div className="ml-[8%] md:ml-0">
            <div className="flex items-start mr-[10%] mb-2">
              <button
                className="bg-accent bg-clip-text text-transparent md:text-white font-bold text-md"
                onClick={onShowSampleClick}
              >
                Read Sample <i className="fa-solid fa-arrow-turn-down ml-2"></i>
              </button>
            </div>
            <img
              src={loadImage(IMAGE_KEY.BOOK_COVER_LARGE)}
              alt="React for Job Seekers Book Cover"
            />
            <div className="w-full md:mx-[8%] ml-[-5%] md:ml-[-8%] flex items-center justify-center mt-[-8%]">
              <Button click={() => navigate("#order")} label="Get the Book" />
            </div>
          </div>
          <div className="md:hidden mt-[10%] w-full">
            <p className="text-dark-gray text-section-desc font-light">
              Authored By
            </p>
            <h2 className="bg-accent bg-clip-text text-transparent text-section-heading text-[2rem] md:text-[2.5rem] ml-[-4px] mb-4">
              Qaifi Khan
            </h2>

            <div>
              <div className="flex flex-row md:space-x-4">
                {getStats("1.7 million", "Enrollments", true, false)}
                {getStats("4.5 rated", "Online Courses")}
                {getStats("3.7 million", "Minutes Taught", false, true)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;
